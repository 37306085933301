import { createSelector } from 'reselect';

const selectCompanyFromStore = (state) => state.company;

export const selectCompany = createSelector([selectCompanyFromStore], (company) => company);

const selectCompanyOfferNameFromStore = (state) => state.company.offerName || 'Products/Services';

export const selectCompanyOfferName = createSelector([selectCompanyOfferNameFromStore], (offerName) => offerName);

const selectstageNamesFromStore = (state) => state?.company?.SalesStageNames;

export const selectCompanySalesStageNames = createSelector([selectstageNamesFromStore], (stageNames) => stageNames);
