import axios from 'axios';
import moment from 'moment-timezone';
import { setAdminToken, setAdminUser } from '../redux/super-admin/admin.action';
import { ToastShow } from '../redux/toast/toast.action';
import { setToken } from '../redux/token/token.action';
import { setCurrentUser } from '../redux/user/user.action';

const setupAxios = (store) => {
  axios.interceptors.request.use((request) => {
    const storeData = store.getState();
    const authToken = storeData.token;
    const authAdminToken = storeData.admin.token;
    const utcTime = moment().utc().format();
    request.headers.time = utcTime;

    const timeZone = moment.tz.guess(true);
    request.headers.timezone = timeZone;

    if (authToken) {
      request.headers.Authorization = `JWT ${authToken}`;
    } else if (authAdminToken) {
      request.headers.Authorization = `JWT ${authAdminToken}`;
    }
    return request;
  });
  axios.interceptors.response.use(
    (res) => {
      const { toast } = res.data;
      if (toast) {
        store.dispatch(ToastShow({ message: res.data.message, type: res.data.response_type }));
        console.log(toast);
      }
      return res;
    },

    (e) => {
      if (e?.response?.status === 400) {
        if (e?.response?.data && e?.response?.data?.toast)
          store.dispatch(
            ToastShow({
              message: e.response.data.message,
              type: e.response.data.response_type,
            })
          );
      } else {
        const storeData = store.getState();
        if (storeData.token !== null || storeData.admin.token !== null) {
          if (e.response.status === 401) {
            store.dispatch(setCurrentUser(null));
            store.dispatch(setToken(null));
            store.dispatch(setAdminUser(null));
            store.dispatch(setAdminToken(null));
            window.location.href = '/login';
            // window.location.reload();
          }
        }
        console.log(e);
      }
      return e?.response;
    }
  );
};

export default setupAxios;

export const axiosGet = (url, data) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  });
};

export const axiosPost = (url, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosPatch = (url, data) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosPut = (url, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosDelete = (url) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`);
};
