import React, { Suspense, useEffect, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import openSocket from 'socket.io-client';

import { setCurrentUser } from './redux/user/user.action';
import { selectToken } from './redux/token/token.selector';
import { selectCurrentUser } from './redux/user/user.selector';
import { selectNotificationCount } from './redux/notification/notification.selector';

import './App.scss';
import ToastNotification from './components/common/toast-notification';
import {
  setNotificationCount,
  setNotificationDiscussion,
  setNotificationHelpChat,
  setNotificationHighlights,
} from './redux/notification/notification.action';
import ForgotPassword from './pages/forgot-password/forgot-password';
import { selectAdminToken, selectAdminUser } from './redux/super-admin/admin.selector';
import { setAdminUser } from './redux/super-admin/admin.action';
import { getAdminCurrentUser } from './services/admin.services';
import { setCompany } from './redux/company/company.action';
import { selectCompany } from './redux/company/company.selector';
import { setUserData } from './utils/helper/user';

const LayOut = React.lazy(() => import('./layout/index'));
const Login = React.lazy(() => import('./pages/login/login'));
const Signup = React.lazy(() => import('./pages/signup/signup'));
const ResendVerification = React.lazy(() => import('./pages/signup/resend-verification'));
const SetPassword = React.lazy(() => import('./pages/set-password/set-password'));

export let socket;

const UnAuthRouteArray = ['/signup', '/resend_link', '/forgot-password', '/set-password'];

function App(props) {
  const history = useHistory();
  let notificationCountRef = useRef(0);
  let userRef = useRef();
  userRef.current = { user: props.user, adminUser: props.adminUser };

  notificationCountRef.current = props?.notificationCount;

  useEffect(() => {
    getData();
    if (props.token) setUserData();

    return () => {
      if (socket) {
        socket.emit('disconnect');
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    connectSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.token, props.user, props.adminUser, props.adminToken]);

  // useEffect(() => {
  //   if (props.user) _webpushrScriptReady();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.user]);

  const connectSocket = async () => {
    if (props.token !== null) {
      socket = await openSocket(process.env.REACT_APP_API_URL, {
        forceNew: true,
        transports: ['websocket'],
        query: {
          token: props.token,
        },
      });
    } else if (props.adminToken !== null) {
      socket = await openSocket(process.env.REACT_APP_API_URL, {
        forceNew: true,
        transports: ['websocket'],
        query: {
          token: props.adminToken,
        },
      });
    }

    if (socket) {
      socket.emit('join-room-notification');
      socket.on('increase_notification_count', (count, type) => {
        if (type && type === 'helpchat') {
          if (!userRef.current.user && userRef.current.admin) {
            props?.setNotificationCount(notificationCountRef.current + count);
            props?.setNotificationHelpChat(true);
            return;
          } else return;
        }

        props?.setNotificationCount(notificationCountRef.current + count);
        if (type && type === 'discussion') {
          props?.setNotificationDiscussion(true);
        }
        if (type && type === 'highlights') {
          props?.setNotificationHighlights(true);
        }
      });

      socket.on('decrease_notification_count', (count, type) => {
        if (notificationCountRef.current >= count) props?.setNotificationCount(notificationCountRef.current - count);
      });

      socket.on('disconnect', () => {
        socket && socket.emit('leave-room-notification');
        socket && socket.emit('join-room-notification');
      });
    }
  };

  const getData = async () => {
    if (props.token == null && props.adminToken == null) {
      let text = history?.location.pathname;
      const myArray = text.split('/');
      if (myArray.length > 2) text = '/' + myArray[1];
      if (!UnAuthRouteArray.includes(text)) return history.push('/login');
    }

    if (props.token !== null && (props.user == null || !props.user?._id || props.company == null)) {
      const res = await setUserData();
      if (!res) return history.push('/login');
    }

    if (props.adminToken !== null && (props.adminUser == null || !props.adminUser?._id)) {
      const res = await getAdminCurrentUser();
      if (res.data.response_type === 'success') {
        props.setAdminUser(res.data.data);
      } else {
        return history.push('/login');
      }
    }
  };
  // function _webpushrScriptReady() {
  //   window.webpushr('attributes', { 'user-id': props.user._id, email: props.user.email });
  // }
  return (
    <div className="">
      <ToastNotification />
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/signup" name="Signup" component={Signup} />
          <Route path="/login" name="Login" component={Login} />
          <Route path="/resend_link" name="Resend_Link" component={ResendVerification} />
          <Route path="/forgot-password/:token" name="Forgot_Password" component={ForgotPassword} />
          <Route path="/set-password/:token" name="set-password" component={SetPassword} />
          {props && ((props.token && props.user) || (props.adminToken && props.adminUser)) && (
            <Route path="/" name="Home" component={LayOut} />
          )}
        </Switch>
      </Suspense>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  user: selectCurrentUser,
  notificationCount: selectNotificationCount,
  adminUser: selectAdminUser,
  adminToken: selectAdminToken,
  company: selectCompany,
});

const mapDispatchToProps = (dispatch) => ({
  setcurrentuser: (user) => dispatch(setCurrentUser(user)),
  setNotificationCount: (count) => dispatch(setNotificationCount(count)),
  setNotificationDiscussion: (count) => dispatch(setNotificationDiscussion(count)),
  setNotificationHighlights: (count) => dispatch(setNotificationHighlights(count)),
  setNotificationHelpChat: (count) => dispatch(setNotificationHelpChat(count)),
  setAdminUser: (user) => dispatch(setAdminUser(user)),
  setCompany: (company) => dispatch(setCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
