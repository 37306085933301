import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import tokenReducer from './token/token.reducer';
import searchReducer from './search/search.reducer';
import toastReducer from './toast/toast.reducer';
import notificationReducer from './notification/notification.reducer';
import adminReducer from './super-admin/admin.reducer';
import introPopUpReducer from './IntroPopUp/IntroPopUp.reducer';
import companyReducer from './company/company.reducer';
import generalReducer from './general/general.reducer';
import leadReducer from './lead/lead.reducer';

const persistConfig = {
  key: 'goals',
  storage,
  whitelist: ['user', 'admin', 'token', 'company'],
};

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  token: tokenReducer,
  search: searchReducer,
  toast: toastReducer,
  notification: notificationReducer,
  introPopUp: introPopUpReducer,
  general: generalReducer,
  company: companyReducer,
  lead: leadReducer
});

export default persistReducer(persistConfig, rootReducer);
