import LeadActionTypes from './lead.type';

const INITIAL_STATE = {
  allLeads: [],
  reloadLeads: false,
  reloadCustomers: false,
  reloadOpportunities: false,
};

const leadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LeadActionTypes.SET_LEAD: {
      return {
        ...state,
        allLeads: action.payload,
      };
    }
    case LeadActionTypes.RELOAD_LEAD: {
      return {
        ...state,
        reloadLeads: !state.reloadLeads,
      };
    }
    case LeadActionTypes.RELOAD_CUSTOMER: {
      return {
        ...state,
        reloadCustomers: !state.reloadCustomers,
      };
    }
    case LeadActionTypes.RELOAD_OPPORTUNITY: {
      return {
        ...state,
        reloadOpportunities: !state.reloadOpportunities,
      };
    }
    default:
      return state;
  }
};

export default leadReducer;